import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Breadcrums from "../components/Breadcrums";

const heading = ({ title,subtitle, image, alt }) => {
  return (
    <section className="heading pb-0">
      <div className="bg-overlay gradient-bg1 opacity-9"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 position-relative">
            <div className="cover-text mt-5">
              <h2 className="text-white mb-2">{title}</h2>
              <p className="text-white">
                {subtitle}
              </p>
              <Breadcrums pageTitle={title}/>
            </div>
          </div>
        </div>
      </div>
      <GatsbyImage className="heading-bg bg-cover-image" image={image} alt="" />
    </section>
  );
};

export default heading;
