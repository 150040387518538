import React from "react";
import { Link } from "gatsby";

export default function Breadcrums({ pageTitle, parentPage}) {
  return (
    <div className="page_nav position-relative d-block float-md-end float-sm-none">
      <span>Estás aquí:</span> <Link to="/">Inicio</Link>
      <span className=" px-1">
        <i className="fa fa-angle-double-right"></i> {pageTitle}
      </span>
    </div>
  );
}
