import React, { useState } from "react"
import { Helmet } from "react-helmet";
import axios from "axios";
import Layout from "../components/Layout";
import Heading from "../components/Heading";
import Address from "../components/sections/contact/Address";
const ContactPage = ({ data }) => {

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/b8235e3f-eeff-41ab-8711-edb116331877",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Gracias! Pronto estaremos en contacto con usted.", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };
  return (
    <Layout>
      <Helmet>
        <title>Contacto | RöDigital</title>
      </Helmet>
      <Heading
        title="Contáctenos"
        subtitle="Ofrecemos los diseños web más creativos."
      />

      <section className="pb-0 address-section">
        <div className="container">
          <div className="row wow fadeIn">
            <div className="col-md-12 text-center">
              <div className="title d-inline-block">
                <h6 className="mb-3">¿Tiene alguna pregunta?</h6>
                <h2 className="gradient-text1 mb-3">Pongámonos en contacto</h2>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-7">
              {serverState.status && (
                <p className={!serverState.status.ok ? "errorMsg" : "okMesg alert alert-success mb-5"}>
                  {serverState.status.msg}
                </p>
              )}
              <form onSubmit={handleOnSubmit} className="contact-form">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <input type="text" name="Nombre" className="form-control" id="Nombre" placeholder="Ingrese su nombre" required="required" />
                  </div>
                  <div className="form-group col-lg-6">
                    <input type="email" name="email" className="form-control" id="Email" aria-describedby="emailHelp" placeholder="Ingrese su Email" />
                  </div>
                  <div className="form-group col-lg-6">
                    <input type="text" name="empresa" className="form-control" id="Empresa" placeholder="Ingrese el nombre de su empresa" />
                  </div>
                  <div className="form-group col-lg-6">
                    <input type="text" name="Telefono" className="form-control" id="Telefono" placeholder="Teléfono de contacto" />
                  </div>
                  <div className="form-group col-lg-6">
                    <input type="text" name="celular" className="form-control" id="Celular" placeholder="Celular" />
                  </div>
                  <div className="form-group col-lg-6">
                    <select className="form-control" id="exampleFormControlSelect1" name="service" required="required">
                      <option value="" disabled selected hidden>Interesado en</option>
                      <option>UX/UI</option>
                      <option>Desarrollo Web</option>
                      <option>Desarrollo de Sofware</option>
                      <option>Desarrollo de Aplicaciones Moviles</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <textarea class="form-control contactformtextarea" placeholder="Por favor déjenos saber como podemos servirle" id="message" name="message"></textarea>
                  </div>
                  <div class="col-sm-12">
                    <button type="submit" class="btn btn-large btn-gradient mt-4" id="submit_btn" disabled={serverState.submitting}><i class="fa fa-spinner fa-spin mr-2 d-none" aria-hidden="true"></i> <span>Contactar</span></button>
                  </div>

                </div>
              </form>
            </div>
            <Address />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;