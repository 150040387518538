import React from "react";
import SocialMedia from "../SocialMedia";
// import ContactForm from "./ContactForm";

const Address = () => {
  return (
    <div className="col-lg-5">
      <div className="address-block">
        <h6 className="mb-3 third-color">
          E-commerce, Websites y Aplicaciones Móviles.
        </h6>
        <h3 className="gradient-text1">RöDigital</h3>
        <ul className="address-list">
          <li>San José, Costa Rica</li>
          <li>Teléfono : <a href="tel:+50640013267">+506 4001 3267</a></li>
          <li>Mail : <a href="mailto:info@rodigital.io">info@rodigital.io</a></li>
          <li>Horario: Lunes-Viernes: 8am a 5pm</li>
        </ul>
        <SocialMedia />
      </div>
    </div>
  );
};

export default Address;
